<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded v-if="registro.gesproducto">
    <v-card flat v-if="registro.gesproducto">
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>PRODUCTO</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'TIPO DE PRODUCTO'" :descripcion="(registro.gesproducto.producto)?registro.gesproducto.producto.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
           <v-autocomplete v-model="form_producto.producto_id" v-on:input="input_producto(form_producto.producto_id)"  :rules="[rules.requerido]" :items="items.productos" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE PRODUCTO *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-autocomplete v-model="form_producto.productodigital_id" :rules="[rules.requerido]" :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="¿ÉS UN PRODUCTO DIGITAL? *"></v-autocomplete>
            <!-- <texto2 :colores="colores" :titulo="'¿ES UN PRODUCTO DIGITAL?'" :descripcion="(registro.gesproducto.productodigital)?registro.gesproducto.productodigital.descripcion:''"></texto2> -->
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="form_producto.numero" :rules="[rules.requerido, rules.numerico]" :color="colores.primario" label="No. DE PRODUCTO *" type="number" min="0" max="999999999999999"></v-text-field>
            <!-- <texto2 :colores="colores" :titulo="'No. DE PRODUCTO'" :descripcion="registro.gesproducto.numero"></texto2> -->
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'PAÍS'" :descripcion="(registro.gesproducto.pais)?registro.gesproducto.pais.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'DEPARTAMENTO'" :descripcion="(registro.gesproducto.departamento)?registro.gesproducto.departamento.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4">
            <texto2 :colores="colores" :titulo="'MUNICIPIO'" :descripcion="(registro.gesproducto.municipio)?registro.gesproducto.municipio.descripcion:''"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="showPlaca">
          <v-col cols="12" md="4">
            <v-text-field v-model="form_vehiculo.placa" maxlength="10" counter="10" :rules="[rules.requerido]" :color="colores.primario" label="PLACA DEL VEHÍCULO *"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
// import texto2 from '@/components/widgets/text2Component.vue'
export default {
  name: 'crm_lectura_clienteComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue')
  },
  props: ['colores', 'registro', 'form_producto', 'form_vehiculo', 'rules', 'items', 'loadingData'],
  data: () => ({
    showPlaca: false
  }),
  created () {
    if (this.form_vehiculo.placa !== '' || (this.form_producto.producto_id === 5 || this.form_producto.producto_id === 7 || this.form_producto.producto_id === 8 || this.form_producto.producto_id === 6)) {
      this.showPlaca = true
    }
    this.rules = this.$store.getters.getRules
  },
  watch: {
  },
  methods: {
    input_producto (pProductoId) {
      this.showPlaca = false
      if (pProductoId === 5 || pProductoId === 7 || pProductoId === 8 || pProductoId === 6) {
        this.showPlaca = true
      } else {
        this.form_producto.placa = ''
      }
    }
  }

}
</script>
